import React, { useEffect, useState } from "react"
import { get } from "lodash"
import { breakpoint } from "@Assets/styles/mixins"
import { containerStyled, ctaStyled } from "@Assets/styles/globalStyle"
import styled from "styled-components"
import { variables } from "@Assets/styles/variables"
import Parallax from "react-rellax"
import ReactWOW from "react-wow"
const InstagramFeed = () => {
  const token = process.env.GATSBY_IG_TOKEN
  const [postInstagram, setPostIngragram] = useState([])
  const [userInstagram, setUserInstagram] = useState([])
  function normalizeResponseFromInstagram({ data = [] }) {
    setUserInstagram(data[0].user.username)
    return data
      .map(post => ({
        id: get(post, "id", ""),
        title: get(post, "caption.text", "instagram-photo"),
        url: get(post, "link", ""),
        imageUrl: get(post, "images.standard_resolution.url", ""),
      }))
      .slice(0, 8)
  }

  // useEffect(() => {
  //   fetch(
  //     `https://api.instagram.com/v1/users/self/media/recent?access_token=${token}`
  //   )
  //     .then(res => res.json())
  //     .then(normalizeResponseFromInstagram)
  //     .then(setPostIngragram)
  // }, [token])

  return (
    <InstagramFeedStyled>
      <InstagramFeedWrapperStyled>
        <InstagramFeedItemStyled>
          <InstagramFeedTitleStyled>
            <ReactWOW animation="fadeInUp">
              <span>byFFMM</span>
            </ReactWOW>
            <Parallax speed={0.5}>
              <ReactWOW animation="fadeInUp" delay="0.4">
                <InstagramFeedColorElementStyled />
              </ReactWOW>
            </Parallax>
          </InstagramFeedTitleStyled>
        </InstagramFeedItemStyled>
        <InstagramFeedGridStyled>
          {postInstagram.length > 0 &&
            postInstagram.map((post, index) => (
              <ReactWOW
                key={post.id}
                animation="fadeInUp"
                offset={100}
                delay={`0.${index}s`}
              >
                <InstagramPostLinkStyled
                  target="_blank"
                  href={post.url}
                  rel="noopener noreferrer"
                >
                  <InstagramPostImageStyled
                    src={post.imageUrl}
                    alt={post.title}
                  />
                </InstagramPostLinkStyled>
              </ReactWOW>
            ))}
        </InstagramFeedGridStyled>
        <InstagramFeedProfileLinkWrapperStyled>
          <ReactWOW animation="fadeInUp">
            <InstagramFeedProfileLinkStyled
              rel="noopener noreferrer"
              target="_blank"
              href={`https://instagram.com/byffmm.directors`}
            >
              Discover More on Instagram
            </InstagramFeedProfileLinkStyled>
          </ReactWOW>
        </InstagramFeedProfileLinkWrapperStyled>
      </InstagramFeedWrapperStyled>
    </InstagramFeedStyled>
  )
}

const InstagramFeedStyled = styled.section`
  width: 100%;
  padding: 80px 0;
  ${breakpoint.lg`
    padding: 150px 0;
  `}
`
const InstagramFeedWrapperStyled = styled.div`
  ${containerStyled}
`
const InstagramFeedItemStyled = styled.div``
const InstagramFeedTitleStyled = styled.h2`
  font-family: ${variables.fontBold};
  position: relative;
  display: inline-block;
  font-size: 32px;
  margin-bottom: 24px;
  > span {
    z-index: 2;
    position: relative;
  }
  ${breakpoint.md`
    font-size: 56px;
    margin-left: 50px;
  `}
  ${breakpoint.lg`
    font-size: 72px;
    margin-left: 100px;
  `}
`
const InstagramFeedColorElementStyled = styled.span`
  height: 40px;
  width: 60%;
  position: absolute;
  z-index: -1;
  right: 0;
  background-color: ${variables.accentColor};
  top: 50px;
  ${breakpoint.md`
    height: 64px;
    top: 0;
  `}
`
const InstagramFeedGridStyled = styled(InstagramFeedItemStyled)`
  display: grid;
  margin-top: 32px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  margin-bottom: 40px;
  ${breakpoint.md`
    margin-top: 40px;
    grid-column-gap: 24px;
  `}
  ${breakpoint.lg`
    grid-column-gap: 24px;
    grid-template-columns: repeat(4, 1fr);
  `}
`
const InstagramPostLinkStyled = styled.a`
  display: block;
  position: relative;
  overflow: hidden;
  &:hover {
    img {
      transform: translateX(-50%) scale(1.04);
    }
  }
  &:after {
    content: "";
    width: 100%;
    display: block;
    padding-bottom: 100%;
  }
`
const InstagramPostImageStyled = styled.img`
  height: 100%;
  position: absolute;
  top: 0;
  transition: transform 0.5s ease-out;
  left: 50%;
  transform: translateX(-50%);
`
const InstagramFeedProfileLinkWrapperStyled = styled(InstagramFeedItemStyled)`
  ${breakpoint.md`
    display: flex;
    justify-content: flex-end;
  `}
`

const InstagramFeedProfileLinkStyled = styled.a`
  ${ctaStyled}
`

export default InstagramFeed
