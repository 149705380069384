import React from "react"
import { breakpoint } from "@Assets/styles/mixins"
import { variables } from "@Assets/styles/variables"
import PropTypes from "prop-types"
import BackgroundLines from "./BackgroundLines"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"
const ModalContact = ({ isOpen, onClose }) => {
  const { animation } = useSpring({
    from: { animation: 0 },
    to: { animation: isOpen ? 1 : 0 },
    config: { mass: 1.5, tension: 246, friction: 26 },
  })
  return (
    <ModalContactExternalStyled
      style={{
        opacity: animation.interpolate({ output: [0, 1] }),
        pointerEvents: isOpen ? "initial" : "none",
      }}
    >
      <ModalContactStyled>
        <ModalContactWrapperStyled
          style={{
            transform: animation
              .interpolate({
                range: [0, 1],
                output: [100, 0],
              })
              .interpolate(animation => `translateY(${animation}px)`),
          }}
        >
          <ModalContactCloserToggleStyled onClick={onClose}>
            <div>
              <span></span>
              <span></span>
            </div>
          </ModalContactCloserToggleStyled>

          <ModalContactItemTextStyled>
            <h2>Matteo Molteni</h2>
            <p>
              <a href="mailto:matteo.molteni.if@gmail.com">
                matteo.molteni.if@gmail.com
              </a>
            </p>
            <p>
              <a href="tel:+393285786583">+39 328 57 86 583</a>
            </p>
            <p>Milan, Italy</p>
          </ModalContactItemTextStyled>
        </ModalContactWrapperStyled>
      </ModalContactStyled>
      <BackgroundLines isBlack={true} />
    </ModalContactExternalStyled>
  )
}

ModalContact.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

ModalContact.defaultProps = {
  isOpen: false,
  onClose: () => {},
}

const ModalContactExternalStyled = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
`
const ModalContactStyled = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-height: 100vh;

  ${breakpoint.down("md")`
    top: 0%;
    transform: translateX(0%) translateY(0%);
    left: 0;
  `}
`
const ModalContactWrapperStyled = styled(animated.div)`
  width: 100%;
  background: white;
  min-height: inherit;
  display: flex;
  ${breakpoint.down("md")`
    flex-wrap: wrap;
    display: block;
  `}
`

const ModalContactItemStyled = styled.div`
  width: 100%;
  flex: auto;
  display: flex;
  align-items: center;
  color: ${variables.primaryColor};
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  ${breakpoint.down("md")`
    flex: 1 1 100%;
    height: 100vh;
  `}
`

const ModalContactItemTextStyled = styled(ModalContactItemStyled)`
  h2 {
    font-size: 21px;
    letter-spacing: 8px;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 16px;
    font-family: ${variables.fontBold};
    ${breakpoint.s`
      font-size: 24px;
    `}
    ${breakpoint.md`
      font-size: 40px;
    `}
    ${breakpoint.lg`
      font-size: 64px;
    `}
  }

  p {
    &:last-of-type {
      text-transform: uppercase;
    }
    a {
      color: ${variables.primaryColor};
      text-decoration: none;
    }
    font-size: 18px;
    line-height: 1.3;
    font-family: ${variables.fontBold};
    max-width: 600px;
    &:last-of-type {
      margin-top: 16px;
    }
    ${breakpoint.md`
      font-size: 32px;
    `}
  }
`

const ModalContactCloserToggleStyled = styled.div`
position: absolute;
top: 40px;
right: 40px;
cursor pointer;
div {
    width: 32px;
    height: 32px;
    position: relative;
  }
  span {
    transition: transform 0.2s ease-out;
    width: 100%;
    height: 4px;
    background-color: black;
    position: absolute;
    &:first-child {
      top: 6px;
      left: 0;
      transform: rotateZ(45deg) translateX(5px) translateY(7px)
    }

    &:last-child {
      bottom: 6px;
      left: 0;
      transform: rotateZ(-45deg) translateX(4px) translateY(-6px);
    }
  }
`
export default ModalContact
