import React from "react"
import { breakpoint } from "@Assets/styles/mixins"
import { containerStyled, ctaStyled } from "@Assets/styles/globalStyle"
import styled from "styled-components"
import { variables } from "@Assets/styles/variables"
import ReactWOW from "react-wow"

const PreFooter = ({ onModalOpen }) => (
  <PreFooterStyled>
    <PreFooterWrapperStyled>
      <PreFooterItemStyled>
        <ReactWOW animation="fadeInUp">
          <PreFooterTitleStyled>.let’s keep in touch</PreFooterTitleStyled>
        </ReactWOW>
        <ReactWOW animation="fadeInUp">
          <PreFooterCtaStyled onClick={onModalOpen}>
            Contact Me
          </PreFooterCtaStyled>
        </ReactWOW>
      </PreFooterItemStyled>
    </PreFooterWrapperStyled>
  </PreFooterStyled>
)

const PreFooterStyled = styled.section`
  width: 100%;
  padding: 80px 0;
  ${breakpoint.lg`
    padding: 150px 0;
  `}
`
const PreFooterWrapperStyled = styled.div`
  ${containerStyled}
`
const PreFooterItemStyled = styled.div``
const PreFooterTitleStyled = styled.h2`
  font-family: ${variables.fontBold};
  font-size: 32px;
  margin-bottom: 48px;
  ${breakpoint.md`
    font-size: 56px;
  `}
  ${breakpoint.lg`
    font-size: 72px;
  `}
`
const PreFooterCtaStyled = styled.a`
  ${ctaStyled}
  margin-left: 32px;
  ${breakpoint.md`
    margin-left: 64px;
  `}
  ${breakpoint.lg`
    margin-left: 80px;
  `}
`

export default PreFooter
