import React, { useState } from "react"
import Layout from "@Components/layout"
import SEO from "@Components/seo"
import Hero from "@Components/Hero"
import PreFooter from "@Components/PreFooter"
import WhoIAm from "@Components/WhoIAm"
import InstagramFeed from "@Components/InstagramFeed"
import ModalContact from "@Components/ModalContact"

const IndexPage = () => {
  const [isOpen, setIsOpen] = useState(false)

  function onModalOpen() {
    setIsOpen(true)
  }

  function onModalClose() {
    setIsOpen(false)
  }

  return (
    <>
      <Layout onModalOpen={onModalOpen}>
        <SEO title="Home" />
        <Hero />
        <WhoIAm />
        <InstagramFeed />
        <PreFooter onModalOpen={onModalOpen} />
      </Layout>
      <ModalContact isOpen={isOpen} onClose={onModalClose} />
    </>
  )
}

export default IndexPage
