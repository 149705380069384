import React from "react"
import { breakpoint } from "@Assets/styles/mixins"
import { ctaStyled } from "@Assets/styles/globalStyle"
import styled from "styled-components"
import { variables } from "@Assets/styles/variables"
import Parallax from "react-rellax"
import ReactWOW from "react-wow"
import PhotoPortrait from "./PhotoPortrait"
import { containerStyled } from "@Assets/styles/globalStyle"

const WhoIAm = () => (
  <WhoIAmStyled>
    <WhoIAmBigTextWrapperStyled>
      <Parallax speed={1.5}>
        <WhoIAmBigTextStyled>
          Matteo <br /> <span>Molteni</span>
        </WhoIAmBigTextStyled>
      </Parallax>
    </WhoIAmBigTextWrapperStyled>
    <WhoIAmWrapperStyled>
      <ReactWOW animation="fadeInUp" delay="0.3">
        <WhoIAmPhotoWrapperStyled>
          <Parallax speed={-0.7} zIndex={3}>
            <WhoIAmColorStripeStyled />
          </Parallax>
          <PhotoPortrait />
        </WhoIAmPhotoWrapperStyled>
      </ReactWOW>
      <WhoIAmItemStyled>
        <ReactWOW animation="fadeInUp">
          <WhoIAmTitleStyled>.who I am</WhoIAmTitleStyled>
        </ReactWOW>
        <ReactWOW animation="fadeInUp">
          <WhoIAmTextStyled>
            You can catch me on a set, in a studio, or in a stadium at the edge
            of field.
            <br /> <br />
            In love about the technical aspect that characterizes every single
            video production, large or small.
            <br />
            <br />
            In 2019 i've started the project called byFFMM with Federico
            Ferramola.
            <br />
            <br />
            <WhoIAmCtaStyled
              target="_blank"
              href="https://vimeo.com/matteomolteni/albums"
            >
              Watch my portfolio
            </WhoIAmCtaStyled>
          </WhoIAmTextStyled>
        </ReactWOW>
      </WhoIAmItemStyled>
    </WhoIAmWrapperStyled>
  </WhoIAmStyled>
)

const WhoIAmStyled = styled.section`
  width: 100%;
  position: relative;
  padding: 80px 0 0px 0;
  ${breakpoint.lg`
    padding: 150px 0;
  `}
`

const WhoIAmBigTextWrapperStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 0.03;
`
const WhoIAmBigTextStyled = styled.p`
  text-transform: uppercase;
  position: relative;
  top: 200px;
  span {
    margin-left: 100px;
  }

  font-family: ${variables.fontBold};
  font-size: 300px;
`

const WhoIAmWrapperStyled = styled.div`
  ${containerStyled}
  display: grid;
  margin-top: 32px;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 24px;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
`
const WhoIAmItemStyled = styled.div`
  grid-column: 1 / -1;
  ${breakpoint.lg`
    grid-column: 6 / -1;
  `}
`

const WhoIAmPhotoWrapperStyled = styled(WhoIAmItemStyled)`
  position: relative;
  z-index: 2;
  .gatsby-image-wrapper {
    z-index: -1;
  }
  ${breakpoint.lg`
    grid-column: 1 / 5;
  `}
`
const WhoIAmColorStripeStyled = styled.span`
  width: 50px;
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  transform: translateX(50%);
  height: 150px;
  background-color: ${variables.accentColor};
  display: block;
`

const WhoIAmTitleStyled = styled.h2`
  font-family: ${variables.fontBold};
  font-size: 32px;
  margin: 24px 0;
  ${breakpoint.md`
      font-size: 56px;
    `}
  ${breakpoint.lg`
      font-size: 80px;
  `}
`
const WhoIAmTextStyled = styled.p`
  font-size: 16px;
  line-height: 2;
  max-width: 445px;
  ${breakpoint.s`
    font-size: 18px;
  `}
  ${breakpoint.md`
    padding-left: 64px;
    font-size: 21px;
  `}
`
const WhoIAmCtaStyled = styled.a`
  ${ctaStyled}
  font-size: 18px;
`

export default WhoIAm
