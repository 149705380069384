import React from "react"
import styled from "styled-components"
import { variables } from "@Assets/styles/variables"
import Vimeo from "@u-wave/react-vimeo"
const HeroVideo = () => (
  <HeroVideoStyled>
    <Vimeo
      video="358624374"
      autoplay
      muted
      loop
      background
      showTitle={false}
      width="100vw"
      height="100%"
    />
  </HeroVideoStyled>
)

const HeroVideoStyled = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;
  z-index: 1;
  > div {
    width: 100%;
    height: 100%;
    iframe {
      z-index: -1;
      width: 100vw;
      height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 100vh;
      min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &::before {
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${variables.primaryColor};
    opacity: 0.6;
    z-index: 0;
  }
  &::after {
    position: absolute;
    bottom: 0;
    height: 60%;
    width: 100%;
    content: " ";
    left: 0;
    background-image: linear-gradient(
      180deg,
      rgba(24, 24, 24, 0) 0%,
      ${variables.primaryColor} 100%
    );
  }
`
export default HeroVideo
