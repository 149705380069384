import React from "react"
import { breakpoint } from "@Assets/styles/mixins"
import { variables } from "@Assets/styles/variables"
import HeroVideo from "./HeroVideo"
import styled, { keyframes } from "styled-components"
import Parallax from "react-rellax"
import ReactWOW from "react-wow"

const Hero = () => (
  <HeroStyled>
    <HeroVideoStyled>
      <HeroVideo />
    </HeroVideoStyled>
    <HeroStyledWrapper>
      <HeroStyledItem>
        <HeroTitleStyled>
          <span>Matteo Molteni</span>
          <Parallax speed={1.5}>
            <ReactWOW animation="fadeInUp">
              <HeroTitleColorElementStyled />
            </ReactWOW>
          </Parallax>
        </HeroTitleStyled>
        <HeroSubTitleStyled>
          Filmmaker and video operator based in Milan.
        </HeroSubTitleStyled>
      </HeroStyledItem>
      <HeroDownLineStyled />
    </HeroStyledWrapper>
  </HeroStyled>
)

const HeroStyled = styled.section`
  width: 100%;
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: ${variables.primaryColor};
`
const HeroVideoStyled = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`

const HeroStyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeroStyledItem = styled.div`
  width: 100%;
  display flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  `

const HeroTitleStyled = styled.h1`
  font-size: 21px;
  letter-spacing: 8px;
  text-transform: uppercase;
  position: relative;
  font-family: ${variables.fontBold};
  > span {
    z-index: 2;
    position: relative;
  }
  ${breakpoint.s`
    font-size: 24px;
  `}
  ${breakpoint.md`
    font-size: 48px;
  `}
  ${breakpoint.lg`
    font-size: 80px;
  `}
`

const HeroSubTitleStyled = styled.p`
  position: relative;
  margin: 0 auto;
  margin-top: 40px;
  font-size: 14px;
  line-height: 1.4;
  text-transform: uppercase;
  max-width: 250px;
  font-family: ${variables.fontBold};
  letter-spacing: 2px;
  text-align: center;
  ${breakpoint.md`
    max-width: 100%;
    font-size: 16px;
  `}
`

const HeroTitleColorElementStyled = styled.span`
  height: 64px;
  width: 60%;
  position: absolute;
  z-index: -1;
  top: -50px;
  right: 0;
  background-color: ${variables.accentColor};
`

const animationHeight = keyframes`
  from {
    height: 0;
    transform: translateY(-50px);
  }

  to {
    height: 50px;
    transform: translateY(0);
  }
`

const HeroDownLineStyled = styled.span`
  width: 1px;
  background: white;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 3;
  transform: translateX(-50%);
  animation: ${animationHeight} 1.5s ease-out forwards;
`

export default Hero
